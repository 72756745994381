@import "minima";

.site-title {
    width: 50%;
}

img.border {
    border: 1px solid #eee;
}

.center-image {
    margin: 0 auto;
    display: block;
    border-radius: 5px;
}

.right-image {
    float: right;
    margin-left: 10px;
    border-radius: 5px;
}

.left-image {
    float: left;
    margin-right: 10px;
    border-radius: 5px;
}

.circle-image {
border-radius: 50%;
}

#acclaim-badge-fh > iframe {
    margin: 0 auto;
    display: block;
}